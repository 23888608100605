let isInit = false

export default defineNuxtRouteMiddleware(async (to) => {
  // if (userStore.isLogin) {
  //   const isLogin = await userStore.fetchUser();
  // }

  if (isInit) {
    return
  }

  const userStore = useUserStore()
  if (!userStore.isLogin) {
    isInit = true
    return
  } else if (['/auth/logout'].includes(to.path)) {
    return
  } else if (!userStore.user.birthday) {
    if (to.path !== '/auth/profile/birthday') {
      return navigateTo('/auth/profile/birthday')
    } else {
      return
    }
  } else if (!userStore.user.isCard) {
    if (to.path !== '/auth/profile/card') {
      return navigateTo('/auth/profile/card')
    } else {
      return
    }
  }

  isInit = true
  console.log('fire isInit is true')
  // // 認証されていない場合は/loginにリダイレクト
  // if (!isAuthenticated.value && to.path !== '/login') {
  //   return navigateTo('/login')
  // }
  // if (to.path !== "/") {
  //   return navigateTo("/");
  // }
})
