import { defineStore } from 'pinia'

export const useUserRewordStore = defineStore('user_reword', {
  state: (): { notsendCount: number } => ({
    notsendCount: 0,
  }),
  actions: {
    async fetchNotsendCount() {
      // ユーザー情報を取得する処理
      try {
        const { data } = await useAPI().get<{ result: boolean, count: number }>('/api/gacha/reward/pending/count')
        if (data.result) {
          this.notsendCount = data.count
          return true
        }
      } catch (error) {
        console.error('Failed to fetch count:', error)
      }
      return false
    },
  },
})
