import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src720938123/src/gachapark/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src720938123/src/gachapark/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src720938123/src/gachapark/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src720938123/src/gachapark/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src720938123/src/gachapark/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src720938123/src/gachapark/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src720938123/src/gachapark/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/codebuild/output/src720938123/src/gachapark/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src720938123/src/gachapark/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src720938123/src/gachapark/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/codebuild/output/src720938123/src/gachapark/frontend/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_Jozdw60ZsE from "/codebuild/output/src720938123/src/gachapark/frontend/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import fontawesome_cn2c4tOOHz from "/codebuild/output/src720938123/src/gachapark/frontend/plugins/fontawesome.ts";
import api_GFfDXud5Cr from "/codebuild/output/src720938123/src/gachapark/frontend/plugins/api.ts";
import filter_MBkhYQrnZm from "/codebuild/output/src720938123/src/gachapark/frontend/plugins/filter.ts";
import init_grdyAU8lhf from "/codebuild/output/src720938123/src/gachapark/frontend/plugins/init.ts";
import recaptcha_85gNSCNFUU from "/codebuild/output/src720938123/src/gachapark/frontend/plugins/recaptcha.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_Jozdw60ZsE,
  fontawesome_cn2c4tOOHz,
  api_GFfDXud5Cr,
  filter_MBkhYQrnZm,
  init_grdyAU8lhf,
  recaptcha_85gNSCNFUU
]