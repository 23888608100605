import { default as confirmO5Jf0qgFQ2Meta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/auth/confirm.vue?macro=true";
import { default as indexXHO8KuC4AVMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/auth/index.vue?macro=true";
import { default as loginRmRZOKPCpAMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/auth/login.vue?macro=true";
import { default as logoutKNc3510tNyMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/auth/logout.vue?macro=true";
import { default as birthdaybthwbFoAEjMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/auth/profile/birthday.vue?macro=true";
import { default as cardRHorol1AazMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/auth/profile/card.vue?macro=true";
import { default as sentiFVCatnT52Meta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/auth/sent.vue?macro=true";
import { default as signupDrhKcLdAsNMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/auth/signup.vue?macro=true";
import { default as _91historyId_93JjM1ocN2jnMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/gacha/animation/[id]/[historyId].vue?macro=true";
import { default as _91id_93twha3cVZ1qMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/gacha/detail/[id].vue?macro=true";
import { default as _91historyId_93IkGmMLwp8OMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/gacha/result/[id]/[historyId].vue?macro=true";
import { default as indexucjT83HYrrMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/index.vue?macro=true";
import { default as addressH5tqAlUm0dMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/mypage/address.vue?macro=true";
import { default as confirm4TNR1hvakQMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/mypage/email/confirm.vue?macro=true";
import { default as indexoRt8INu8UqMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/mypage/email/index.vue?macro=true";
import { default as indexp4OGvOwLCUMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/mypage/index.vue?macro=true";
import { default as cardJ1mWqaZBHyMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/mypage/payment/card.vue?macro=true";
import { default as indexdnHOHY8fdgMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/mypage/payment/index.vue?macro=true";
import { default as profilex2eR80q2cRMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/mypage/profile.vue?macro=true";
import { default as commercial_45transactions8P9ES6TQUZMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/s/commercial-transactions.vue?macro=true";
import { default as cookie9NfOw39TgdMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/s/cookie.vue?macro=true";
import { default as privacypolicytObCqiadNfMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/s/privacypolicy.vue?macro=true";
import { default as termshHNqvfh2sBMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/s/terms.vue?macro=true";
import { default as addressvBp2xydsskMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/pending/address.vue?macro=true";
import { default as _91shippingRequestId_93TM0OMu9GhtMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/pending/complete/[shippingRequestId].vue?macro=true";
import { default as _91id_931VrJ3x7rEhMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/pending/detail/[id].vue?macro=true";
import { default as indexPl8RZ87gfxMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/pending/index.vue?macro=true";
import { default as requestTEFLHmBQ1IMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/pending/request.vue?macro=true";
import { default as _91shippingRequestId_93XbctI5kC6VMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/requested/detail/[shippingRequestId].vue?macro=true";
import { default as indexBSNq8xd5yKMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/requested/index.vue?macro=true";
import { default as _91shippingRequestId_9300esrcqaJhMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/sent/detail/[shippingRequestId].vue?macro=true";
import { default as indexEWLshtzX0GMeta } from "/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/sent/index.vue?macro=true";
import { default as terms72SNlwzE7DMeta } from "~/pages/s/terms.vue?macro=true";
import { default as privacypolicymEMnMHHTJ4Meta } from "~/pages/s/privacypolicy.vue?macro=true";
import { default as commercial_45transactions2aHff54B4WMeta } from "~/pages/s/commercial-transactions.vue?macro=true";
import { default as cookieE5bXYIwJsfMeta } from "~/pages/s/cookie.vue?macro=true";
export default [
  {
    name: "auth-confirm",
    path: "/auth/confirm",
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/auth/confirm.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-profile-birthday",
    path: "/auth/profile/birthday",
    meta: birthdaybthwbFoAEjMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/auth/profile/birthday.vue").then(m => m.default || m)
  },
  {
    name: "auth-profile-card",
    path: "/auth/profile/card",
    meta: cardRHorol1AazMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/auth/profile/card.vue").then(m => m.default || m)
  },
  {
    name: "auth-sent",
    path: "/auth/sent",
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/auth/sent.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "gacha-animation-id-historyId",
    path: "/gacha/animation/:id()/:historyId()",
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/gacha/animation/[id]/[historyId].vue").then(m => m.default || m)
  },
  {
    name: "gacha-detail-id",
    path: "/gacha/detail/:id()",
    meta: _91id_93twha3cVZ1qMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/gacha/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "gacha-result-id-historyId",
    path: "/gacha/result/:id()/:historyId()",
    meta: _91historyId_93IkGmMLwp8OMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/gacha/result/[id]/[historyId].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mypage-address",
    path: "/mypage/address",
    meta: addressH5tqAlUm0dMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/mypage/address.vue").then(m => m.default || m)
  },
  {
    name: "mypage-email-confirm",
    path: "/mypage/email/confirm",
    meta: confirm4TNR1hvakQMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/mypage/email/confirm.vue").then(m => m.default || m)
  },
  {
    name: "mypage-email",
    path: "/mypage/email",
    meta: indexoRt8INu8UqMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/mypage/email/index.vue").then(m => m.default || m)
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: indexp4OGvOwLCUMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/mypage/index.vue").then(m => m.default || m)
  },
  {
    name: "mypage-payment-card",
    path: "/mypage/payment/card",
    meta: cardJ1mWqaZBHyMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/mypage/payment/card.vue").then(m => m.default || m)
  },
  {
    name: "mypage-payment",
    path: "/mypage/payment",
    meta: indexdnHOHY8fdgMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/mypage/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "mypage-profile",
    path: "/mypage/profile",
    meta: profilex2eR80q2cRMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/mypage/profile.vue").then(m => m.default || m)
  },
  {
    name: "s-commercial-transactions",
    path: "/s/commercial-transactions",
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/s/commercial-transactions.vue").then(m => m.default || m)
  },
  {
    name: "s-cookie",
    path: "/s/cookie",
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/s/cookie.vue").then(m => m.default || m)
  },
  {
    name: "s-privacypolicy",
    path: "/s/privacypolicy",
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/s/privacypolicy.vue").then(m => m.default || m)
  },
  {
    name: "s-terms",
    path: "/s/terms",
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/s/terms.vue").then(m => m.default || m)
  },
  {
    name: "shipping-pending-address",
    path: "/shipping/pending/address",
    meta: addressvBp2xydsskMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/pending/address.vue").then(m => m.default || m)
  },
  {
    name: "shipping-pending-complete-shippingRequestId",
    path: "/shipping/pending/complete/:shippingRequestId()",
    meta: _91shippingRequestId_93TM0OMu9GhtMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/pending/complete/[shippingRequestId].vue").then(m => m.default || m)
  },
  {
    name: "shipping-pending-detail-id",
    path: "/shipping/pending/detail/:id()",
    meta: _91id_931VrJ3x7rEhMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/pending/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "shipping-pending",
    path: "/shipping/pending",
    meta: indexPl8RZ87gfxMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/pending/index.vue").then(m => m.default || m)
  },
  {
    name: "shipping-pending-request",
    path: "/shipping/pending/request",
    meta: requestTEFLHmBQ1IMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/pending/request.vue").then(m => m.default || m)
  },
  {
    name: "shipping-requested-detail-shippingRequestId",
    path: "/shipping/requested/detail/:shippingRequestId()",
    meta: _91shippingRequestId_93XbctI5kC6VMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/requested/detail/[shippingRequestId].vue").then(m => m.default || m)
  },
  {
    name: "shipping-requested",
    path: "/shipping/requested",
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/requested/index.vue").then(m => m.default || m)
  },
  {
    name: "shipping-sent-detail-shippingRequestId",
    path: "/shipping/sent/detail/:shippingRequestId()",
    meta: _91shippingRequestId_9300esrcqaJhMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/sent/detail/[shippingRequestId].vue").then(m => m.default || m)
  },
  {
    name: "shipping-sent",
    path: "/shipping/sent",
    meta: indexEWLshtzX0GMeta || {},
    component: () => import("/codebuild/output/src720938123/src/gachapark/frontend/pages/shipping/sent/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("~/pages/s/terms.vue").then(m => m.default || m)
  },
  {
    name: "privacypolicy",
    path: "/privacypolicy",
    component: () => import("~/pages/s/privacypolicy.vue").then(m => m.default || m)
  },
  {
    name: "commercial-transactions",
    path: "/commercial-transactions",
    component: () => import("~/pages/s/commercial-transactions.vue").then(m => m.default || m)
  },
  {
    name: "cookie",
    path: "/cookie",
    component: () => import("~/pages/s/cookie.vue").then(m => m.default || m)
  }
]