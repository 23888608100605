/**
 * 初期化
 */
export default defineNuxtPlugin(async () => {
  const userStore = useUserStore()
  await userStore.fetchUser()
  if (userStore.isLogin) {
    await useUserRewordStore().fetchNotsendCount()
  }

  // if (useUserStore().user.birthday) {
  // useRouter().push("/mypage");
  // }
})
