import { defineStore } from 'pinia'
import type { User } from '~/types/user'

export const useUserStore = defineStore('user', {
  state: (): { user: User } => ({
    user: {
      id: null,
      name: null,
      email: null,
      birthday: null,
      isCard: false,
    },
  }),
  actions: {
    async fetchUser() {
      // ユーザー情報を取得する処理
      try {
        const { data } = await useAPI().get<{ user: User }>('/api/user/me')
        if (data && data.user) {
          this.set(data.user)
          return true
        }
      } catch (error) {
        console.error('Failed to fetch user:', error)
        this.set({})
      }
      return false
    },
    set(param: Partial<User>) {
      this.user.id = param.id || this.user.id
      this.user.email = param.email || this.user.email
      this.user.name = param.name || this.user.name
      this.user.birthday = param.birthday || this.user.birthday
      this.user.isCard = param.isCard || this.user.isCard
    },
    reset() {
      this.user.id = null
      this.user.email = null
      this.user.name = null
      this.user.birthday = null
      this.user.isCard = false
    },
  },
  getters: {
    isLogin: state => !!state.user.id,
  },
})
