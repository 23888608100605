/**
 * 数字を単位付きで表示する
 * @param {string} value 変換する値
 */
export function withUnit(value: number): string {
  const digitsNumber = String(value).length
  if (digitsNumber >= 7) {
    return `${Math.round(value / 100000) / 10}m`
  }
  if (digitsNumber >= 4) {
    return `${Math.round(value / 100) / 10}k`
  }
  return String(value)
}
