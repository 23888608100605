import type {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from 'axios'
import axios from 'axios'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const alert = useAlertStore()

  // 共通エラー定義
  interface ApiResponeError {
    result: boolean
    code: number
    message?: string
    reason?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors?: Array<any>
  }

  const instance: AxiosInstance = axios.create({
    baseURL: config.public.apiBaseUrl,
    timeout: 0,
    withCredentials: true,
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'Cache-Control': 'no-cache, no-store',
      // Pragma: 'no-cache',
    },
  })

  instance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    (error: AxiosError<ApiResponeError>) => {
      if (config.env !== 'production') {
        console.error(error)
      }

      if (error.code === 'ECONNREFUSED' || error.message === 'Network Error') {
        alert.show('インターネットに接続されていません。', 'warning')
        return errorResponseBuilder(error)
      }

      if (error.response && error.response.data.code) {
        const code = error.response.data.code
        if (code >= 90000) {
          // no code
        } else if (error.response.data.reason) {
          alert.show(error.response.data.reason, 'warning')
          // } else if (ServiceError[code]) {
          //   alert.show(ServiceError[code], "warning");
        }
      }

      if (error.response) {
        if (error.response.status === 400) {
          // nocode
        } else if (error.response.status === 401) {
          alert.show(
            '認証の有効期限が切れました。再度ログインを行ってください。',
            'warning'
          )
          navigateTo('/auth/logout')
        } else if (error.response.status === 404) {
          navigateTo('/notfound')
        } else if (error.response.status === 500) {
          alert.show('エラーが発生しました。', 'warning')
        } else {
          alert.show(
            `エラーが発生しました (コード${error.response.status})`,
            'warning'
          )
        }
      }
      return errorResponseBuilder(error)
    }
  )

  // エラーレスポンス値を作成する
  function errorResponseBuilder(error: AxiosError<ApiResponeError>) {
    if (!error.response || !error.response.data) {
      return Promise.resolve({
        ...error,
        data: {
          result: false,
        },
      })
    }
    return Promise.resolve({
      ...error,
      data: {
        result: error.response.data.result,
        code: error.response.data.code,
        errors: error.response.data.errors,
      },
    })
  }

  // Expose to useNuxtApp().$api
  return {
    provide: { api: instance },
  }
})
