<script setup lang="ts">
import type { NuxtError } from '#app'

defineProps({
  error: Object as () => NuxtError,
})

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <div v-if="error">
    <template v-if="error.statusCode === 404">
      ページが見つかりません
    </template>
    <template v-if="error.statusCode === 500">
      エラーが発生しました。
    </template>
    <button @click="handleError">トップに戻る</button>
  </div>
</template>
