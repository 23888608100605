
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/ja'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'


dayjs.extend(updateLocale)

dayjs.extend(isSameOrBefore)
dayjs.extend(relativeTime)
dayjs.extend(utc)



// defaultLocale: "ja"


dayjs.updateLocale("ja")
dayjs.locale('ja')


export default dayjs
