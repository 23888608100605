import { defineStore } from 'pinia'

export interface AlertStoreState {
  display: boolean
  type: 'primary' | 'warning'
  message: string
}

export const useAlertStore = defineStore('alert', {
  state: (): AlertStoreState => ({
    // 通知を表示するか
    display: false,
    // 通知のタイプ
    type: 'primary',
    // メッセージ表示
    message: '保存が完了しました',
  }),
  actions: {
    // 通知を表示させる
    show(message: string, type?: AlertStoreState['type']) {
      this.display = true
      this.type = type || 'primary'
      this.message = message
    },
    // 通知表示を反転させる
    toggleDisplay() {
      this.display = !this.display
    },
  },
})
