import { VueReCaptcha } from 'vue-recaptcha-v3'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueReCaptcha, {
    siteKey: useRuntimeConfig().public.recaptchaKey,
    loaderOptions: {
      autoHideBadge: true,
    },
  })
})
